/* eslint-disable no-underscore-dangle */
import { getCartId, setCartId } from '@helper_cartid';
import { getLoginInfo } from '@helper_auth';
import { getVendor } from '@helper_cookies';
import { modules } from '@config';
import Router from 'next/router';
import React, { useState } from 'react';
import TagManager from 'react-gtm-module';
import { getGuestCartId as queryGetGuestCartId, getCustomerCartId, addConfigurableProductsToCart } from '@core_modules/product/services/graphql';
import Link from 'next/link';

const response = (
    <>
        <p>
            <span>Product berhasil ditambahkan ke Keranjang Belanja.</span>
            <Link href="/checkout/cart">
                <a>
                    Lihat Keranjang
                </a>
            </Link>
        </p>
    </>
);

const CoreSimpleOptionItem = ({
    setOpen = () => {},
    t,
    data,
    View,
    handleAddToCart: CustomAddToCart,
    loading: customLoading,
    setLoading: setCustomLoading,
    checkCustomizableOptionsValue,
    errorCustomizableOptions,
    customizableOptions,
    ...other
}) => {
    const [qty, setQty] = React.useState(1);
    let cartId = '';
    let isLogin = '';
    const {
        __typename, sku, name, categories, /* price_range, */
        stock_status, url_key, review, sale, vendor_product_data = [],
    } = data;

    if (typeof window !== 'undefined') {
        isLogin = getLoginInfo();
        cartId = getCartId();
    }

    const reviewValue = parseInt(review?.rating_summary, 0) / 20;
    const [addConfigurableProducts] = addConfigurableProductsToCart();
    const [getGuestCartId] = queryGetGuestCartId();
    const cartUser = getCustomerCartId();
    let [loading, setLoading] = useState(false);

    if (typeof customLoading !== 'undefined' && typeof setCustomLoading === 'function') {
        loading = customLoading;
        setLoading = setCustomLoading;
    }

    const addToCart = async () => {
        let customizable_options = [];
        const entered_options = [];
        const uids = [];

        if (modules.product.customizableOptions.enabled && customizableOptions && customizableOptions.length > 0) {
            customizableOptions.map((op) => {
                if (customizable_options.length > 0) {
                    const findOptions = customizable_options.find((item) => item.id === op.option_id);
                    if (findOptions) {
                        customizable_options = customizable_options.filter((item) => item.id !== op.option_id);
                        if (op.isEnteredOption) {
                            entered_options.push({
                                uid: op.uid,
                                option_id: op.option_id,
                                value: `${findOptions.value_string},${op.value}`,
                            });
                        } else {
                            customizable_options.push({
                                id: op.option_id,
                                value_string: `${findOptions.value_string},${op.value}`,
                            });
                        }
                    } else if (op.isEnteredOption) {
                        entered_options.push({
                            uid: op.uid,
                            option_id: op.option_id,
                            value: op.value,
                        });
                    } else {
                        customizable_options.push({
                            id: op.option_id,
                            value_string: op.value,
                        });
                    }
                }
                if (customizable_options.length === 0) {
                    if (
                        op.__typename === 'CustomizableFieldValue'
                        || op.__typename === 'CustomizableAreaValue'
                        || op.__typename === 'CustomizableDateValue'
                    ) {
                        entered_options.push({
                            uid: op.uid,
                            option_id: op.option_id,
                            value: op.value,
                        });
                    } else {
                        uids.push(op.uid);
                    }
                }
                return op;
            });
        }

        if (CustomAddToCart && typeof CustomAddToCart === 'function') {
            CustomAddToCart({
                ...data,
                qty: parseFloat(qty),
                customizable_options,
            });
        } else {
            setLoading(true);
            const errorMessage = {
                variant: 'error',
                text: t('product:failedAddCart'),
                open: true,
            };
            if (!cartId || cartId === '' || cartId === undefined) {
                if (!isLogin) {
                    await getGuestCartId()
                        .then((res) => {
                            const token = res.data.createEmptyCart;
                            cartId = token;
                            setCartId(token);
                        })
                        .catch((e) => {
                            const originalError = e.message.includes(':') ? e.message.split(':')[1] : e.message;
                            setLoading(false);
                            window.toastMessage({
                                ...errorMessage,
                                text: originalError || errorMessage.text,
                            });
                        });
                } else if (cartUser.data && cartUser.data.customerCart) {
                    const token = cartUser.data.customerCart.id || '';
                    cartId = token;
                    setCartId(token);
                }
            }
            if (__typename === 'SimpleProduct') {
                const variables = {
                    cartId,
                    cartItems: [
                        {
                            quantity: parseFloat(qty),
                            sku,
                            selected_options: [...uids],
                            entered_options,
                            vendor_id: getVendor(),
                        },
                    ],
                };

                // GA 4 dataLayer
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'add_to_cart',
                        ecommerce: {
                            action: {
                                items: [
                                    {
                                        item_name: name,
                                        item_id: sku,
                                        price: vendor_product_data[0]?.final_price || 0,
                                        item_category: categories.length > 0 ? categories[0].name : '',
                                        item_list_name: categories.length > 0 ? categories[0].name : '',
                                        quantity: qty,
                                        currency: vendor_product_data[0]?.currency_code || 'USD',
                                        item_stock_status: stock_status,
                                        item_reviews_score: reviewValue || 0,
                                        item_reviews_count: review.reviews_count,
                                        item_sale_product: sale === 0 ? 'NO' : 'YES',
                                    },
                                ],
                            },
                        },
                    },
                });

                addConfigurableProducts({
                    variables,
                })
                    .then((res) => {
                        const dataResponse = res.data.addProductsToCartVendor;
                        if (dataResponse.user_errors && dataResponse.user_errors.length > 0) {
                            setLoading(false);
                            const originalError = dataResponse.user_errors[0].message.includes(':')
                                ? dataResponse.user_errors[0].message.split(':')[1]
                                : dataResponse.user_errors[0].message;

                            window.toastMessage({
                                ...errorMessage,
                                text: originalError || errorMessage.text,
                            });
                        } else {
                            window.reloadCartQty = true;
                            // window.toastMessage({ variant: 'success', text: t('product:successAddCart'), open: true });
                            window.toastMessage({ variant: 'success', text: response, open: true });
                            setLoading(false);
                            setOpen(false);
                        }
                    })
                    .catch((e) => {
                        if (e.message === "The product's required option(s) weren't entered. Make sure the options are entered and try again.") {
                            Router.push(`/${url_key}`);
                        }
                        setLoading(false);
                        const originalError = e.message.includes(':') ? e.message.split(':')[1] : e.message;

                        window.toastMessage({
                            ...errorMessage,
                            text: originalError || errorMessage.text,
                        });
                    });
            }
        }
    };

    const handleAddToCart = async () => {
        if (modules.product.customizableOptions.enabled && customizableOptions && customizableOptions.length > 0) {
            const check = await checkCustomizableOptionsValue();
            if (check) {
                addToCart();
            }
        } else {
            addToCart();
        }
    };
    return (
        <View
            qty={qty}
            setQty={setQty}
            handleAddToCart={handleAddToCart}
            t={t}
            loading={loading}
            // disabled={stock_status === 'OUT_OF_STOCK'}
            {...other}
        />
    );
};

export default CoreSimpleOptionItem;
