/* eslint-disable consistent-return */
import Link from 'next/link';
import { categoryHomepage } from '@core_modules/home/service/graphql/index';
import useStyles from './style';
import HomeCategorySkeleton from './HomeCategorySkeleton';

const Categories = () => {
    const styles = useStyles();
    const { data, loading } = categoryHomepage();

    if (loading) {
        return <HomeCategorySkeleton />;
    }

    if (data && data.categories) {
        const categoryData = data && data.categories && data.categories.items;
        return (
            <div className={styles.container}>
                <h3 className={styles.categoryTitle}>kategori Produk</h3>
                <div className={styles.sliderWrapper}>
                    {
                        categoryData
                        && categoryData.length > 0
                        && categoryData.map((category, i) => (
                            <Link href="/[...slug]" as={`/${category.url_key}`} key={i}>
                                <a className={styles.categoryWrapper}>
                                    <img className={styles.categoryImage} src={category.image} alt={category.name} />
                                    <p className={styles.categoryName}>{category.name}</p>
                                </a>
                            </Link>
                        ))
                    }
                </div>
            </div>
        );
    }

    return null;
};

export default Categories;
