import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '26px 40px 60px 35px',
        [theme.breakpoints.down('sm')]: {
            padding: '25px 16px',
        },
    },
    sliderWrapper: {
        width: '100%',
        overflowX: 'auto',
        display: 'grid',
        gridTemplateColumns: 'repeat(6, 1fr)',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'repeat(6, 120px)',
            gridTemplateRows: '125px 125px',
        },
        '&::-webkit-scrollbar': {
            width: '0',
        },
    },
    categoryTitle: {
        textAlign: 'center',
        fontWight: 'bold',
        fontSize: '30px',
        marginBottom: '35px',
        textTransform: 'capitalize',
        [theme.breakpoints.down('sm')]: {
            fontSize: '22px',
        },
    },
    categoryWrapper: {
        border: '1px solid #F1F1F1',
        textAlign: 'center',
        padding: '23px',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: '10px',
            height: '125px',
            width: '120px',
        },
    },
    categoryImage: {
        width: '100%',
        height: '141px',
        objectFit: 'cover',
        [theme.breakpoints.down('sm')]: {
            height: '80px',
            width: '60px',
            objectFit: 'contain',
        },
    },
    categoryName: {
        fontSize: '16px',
        margin: 0,
        padding: 0,
        marginTop: '7px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            marginTop: '0',
            lineHeight: '12px',
        },
    },
    skeletonTitle: {
        display: 'flex',
        justifyContent: 'center',
    },
    skeletonGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        gridGap: '8px',
        marginTop: '16px',
    },
}));

export default useStyles;
