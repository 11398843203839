/* eslint-disable import/prefer-default-export */

import { gql } from '@apollo/client';

export const getRegion = gql`
    query getRegions($country_id: String!) {
        getRegions(country_id: $country_id) {
            item {
                code
                name
                region_id
            }
        }
    }
`;

export const getCmsBlocks = gql`
    query($identifiers: [String]) {
        cmsBlocks(identifiers: $identifiers) {
            items {
                identifier
                title
                content
            }
        }
    }
`;

export const getCountries = gql`
    {
        countries {
            id
            full_name_locale
            full_name_english
        }
    }
`;

export const getCityByRegionId = gql`
    query Cities($regionId: Int!) {
        getCityByRegionId(region_id: $regionId) {
            item {
                id
                city
                postcode
            }
        }
    }
`;

export const customerWishlist = gql`
    query customerWishlist($sharing_code: ID) {
        customerWishlist(sharing_code: $sharing_code) {
            items {
                added_at
                description
                id
                product {
                    id
                    name
                    url_key
                    sku
                    small_image {
                        url
                    }
                    price_range {
                        minimum_price {
                            discount {
                                amount_off
                                percent_off
                            }
                            final_price {
                                currency
                                value
                            }
                            fixed_product_taxes {
                                amount {
                                    currency
                                    value
                                }
                                label
                            }
                            regular_price {
                                currency
                                value
                            }
                        }
                        maximum_price {
                            discount {
                                amount_off
                                percent_off
                            }
                            final_price {
                                currency
                                value
                            }
                            fixed_product_taxes {
                                amount {
                                    currency
                                    value
                                }
                                label
                            }
                            regular_price {
                                currency
                                value
                            }
                        }
                    }
                }
                qty
            }
            items_count
            name
            sharing_code
            updated_at
        }
    }
`;

export const shareWishlist = gql`
    mutation shareWishlist($emails: [ID]!, $message: String) {
        shareWishlist(input: { emails: $emails, message: $message })
    }
`;

// schema settingsPage

export const updateCustomer = gql`
    mutation updateCustomerSetting($isSubscribed: Boolean!) {
        updateCustomer(input: { is_subscribed: $isSubscribed }) {
            customer {
                is_subscribed
            }
        }
    }
`;

export const getCustomerSettings = gql`
    {
        customer {
            firstname
            lastname
            email
            is_subscribed
        }
    }
`;

const productDetail = (config = {}) => `
    id
    name
    sku
    stock_status
    url_key
    __typename
    attribute_set_id
    small_image{
      url
    }
    ${
    config.vendorId ? `
            vendor_product_data(
            selectedStore: ${config.vendorId}
            ) {
            currency_code
            currency_symbol
            pareto_price
            final_pareto_price
            pareto_price_min_qty
            price
            final_price
            sku
            special_price
            final_special_price
            special_price_from
            special_price_to
            stock
            tier_price {
                customer_group
                price
                final_price
                quantity
            }
            vendor_id
            }
        ` : ''
}
    ${
        config?.pwa?.label_weltpixel_enable
            ? `
        weltpixel_labels {
        categoryLabel {
            css
            customer_group
            image
            page_position
            position
            priority
            text
            text_padding
            text_bg_color
            text_font_size
            text_font_color          
        }
        productLabel {
            css
            customer_group
            image
            page_position
            position
            priority
            text
            text_padding
            text_bg_color
            text_font_size
            text_font_color  
        }
    }        
    `
            : ''
}
    image{
      url
    }
    review {
      rating_summary
      reviews_count
    }
    special_from_date
    special_to_date
    `;

const priceRange = `
    price_range {
      minimum_price {
        discount {
          amount_off
          percent_off
        }
        final_price {
          currency
          value
        }
        fixed_product_taxes {
          amount {
            currency
            value
          }
          label
        }
        regular_price {
          currency
          value
        }
      }
      maximum_price {
         discount {
          amount_off
          percent_off
        }
        final_price {
          currency
          value
        }
        fixed_product_taxes {
          amount {
            currency
            value
          }
          label
        }
        regular_price {
          currency
          value
        }
      }
    }
    `;

const priceTiers = `
    price_tiers {
      discount {
        amount_off
        percent_off
      }
      final_price {
        currency
        value
      }
      quantity
    }
    `;

export const getCustomer = (config = {}) => gql`
{
    customer {
      id
      firstname
      lastname
      email
      is_subscribed
      phonenumber
      telephone
      whatsapp_number
      addresses {
        id
        city
        default_billing
        default_shipping
        extension_attributes {
            attribute_code
            value
        }
        firstname
        lastname
        postcode
        country_code
        country {
          code
          label
        }
        region {
            region
            region_code
        }
        street
        telephone
        latitude
        longitude
    }
    wishlists {
        items_v2 {
            items {
                id
                product {
                  ${productDetail(config)}
                  ${priceRange}
                  ${priceTiers}
                }
            }
        }
    }
    }
  }
`;

export const removeToken = gql`
    mutation {
        internalDeleteCustomerToken {
            result
        }
    }
`;

export const customerNotificationList = gql`
    query customerNotificationList {
        customerNotificationList {
            totalUnread
            items {
                content
                createdAt
                entityId
                subject
                unread
            }
        }
    }
`;

export const getGiftCard = gql`
    {
        customer {
            gift_card {
                giftcard_code
                giftcard_balance
            }
        }
    }
`;

export const checkBalance = gql`
    query checkBalance($gift_card_code: String!) {
        giftCardAccount(input: { gift_card_code: $gift_card_code }) {
            code
            balance
            initial_balance
            expiration_date
        }
    }
`;

export const updatedDefaultAddress = gql`
    mutation updatedDefaultAddress($addressId: Int!, $street: String!) {
        updateCustomerAddress(id: $addressId, input: { default_billing: true, default_shipping: true, street: [$street] }) {
            id
            city
            default_billing
            default_shipping
        }
    }
`;

export const updateCustomerAddress = gql`
    mutation updateCustomerAddress(
        $city: String!
        $countryCode: CountryCodeEnum!
        $defaultBilling: Boolean!
        $defaultShipping: Boolean!
        $firstname: String!
        $lastname: String!
        $telephone: String!
        $postcode: String!
        $street: String!
        $addressId: Int!
        $region: String!
        $regionCode: String
        $regionId: Int
        $longitude: String
        $latitude: String
    ) {
        updateCustomerAddress(
            id: $addressId
            input: {
                city: $city
                country_code: $countryCode
                country_id: $countryCode
                default_billing: $defaultBilling
                default_shipping: $defaultShipping
                firstname: $firstname
                lastname: $lastname
                postcode: $postcode
                street: [$street]
                telephone: $telephone
                region: { region: $region, region_code: $regionCode, region_id: $regionId }
                longitude: $longitude
                latitude: $latitude
            }
        ) {
            id
            city
            default_billing
            default_shipping
        }
    }
`;

export const createCustomerAddress = gql`
    mutation createCustomerAddress(
        $city: String!
        $countryCode: CountryCodeEnum!
        $defaultBilling: Boolean!
        $defaultShipping: Boolean!
        $firstname: String!
        $lastname: String!
        $telephone: String!
        $postcode: String!
        $street: String!
        $region: String!
        $regionCode: String
        $regionId: Int
        $longitude: String
        $latitude: String
    ) {
        createCustomerAddress(
            input: {
                city: $city
                country_code: $countryCode
                country_id: $countryCode
                default_billing: $defaultBilling
                default_shipping: $defaultShipping
                firstname: $firstname
                lastname: $lastname
                postcode: $postcode
                street: [$street]
                telephone: $telephone
                region: { region: $region, region_code: $regionCode, region_id: $regionId }
                longitude: $longitude
                latitude: $latitude
            }
        ) {
            id
            city
            default_billing
            default_shipping
        }
    }
`;

export const updateCustomerProfile = gql`
    mutation updateCustomer(
        $firstname: String!
        $lastname: String!
        $email: String!
        $password: String!
        $whatsapp_number: String
        $phonenumber: String
    ) {
        updateCustomerCustom(
            input: {
                firstname: $firstname
                lastname: $lastname
                email: $email
                password: $password
                whatsapp_number: $whatsapp_number
                phonenumber: $phonenumber
            }
        ) {
            customer {
                id
                firstname
                lastname
                email
                phonenumber
                is_phonenumber_valid
                customer_group
            }
        }
    }
`;

export const changeCustomerPassword = gql`
    mutation changeCustomerPassword($currentPassword: String!, $newPassword: String!) {
        changeCustomerPassword(currentPassword: $currentPassword, newPassword: $newPassword) {
            firstname
            lastname
            email
        }
    }
`;

export const addSimpleProductsToCart = gql`
    mutation addSimpleProductsToCart($cartId: String!, $qty: Float!, $sku: String!) {
        addSimpleProductsToCart(input: { cart_id: $cartId, cart_items: { data: { quantity: $qty, sku: $sku } } }) {
            cart {
                id
                total_quantity
            }
        }
    }
`;

export const removeWishlist = gql`
    mutation removeWishlist($wishlistItemId: Int!) {
        removeItemWishlist(wishlistItemId: $wishlistItemId) {
            info
        }
    }
`;

export const removeAddress = gql`
    mutation deleteCustomerAddress($id: Int!) {
        deleteCustomerAddress(id: $id)
    }
`;

export const getCartIdUser = gql`
    {
        customerCart {
            id
        }
    }
`;

export const setNewPassword = gql`
    mutation($password: String!, $confirmPassword: String!, $token: String!) {
        setNewPassword(input: { password: $password, password_confirmation: $confirmPassword, token: $token }) {
            info
        }
    }
`;

export const getCustomerOrder = gql`
    {
        customerOrders(pageSize: 5) {
            items {
                id
                grand_total
                order_number
                status
                status_label
                created_at
                detail {
                    global_currency_code
                    shipping_address {
                        firstname
                        lastname
                    }
                    grand_total
                }
            }
        }
    }
`;

export const subscribeNewsletter = gql`
    mutation updateCustomer($email: String!) {
        subscribe(input: { email: $email }) {
            status {
                code
                message
                response
            }
        }
    }
`;

export const reOrder = gql`
    mutation reOrder($order_id: String!) {
        reorder(input: { order_id: $order_id }) {
            cart_id
        }
    }
`;

export const caRolesList = gql`
    query {
        caRolesList {
            items {
                id
                name
                users
        }
    }
}
`;

export const GET_CA_ROLES = gql`
    query {
        caRolesList {
            items {
                id
                name
            }
        }
    }
`;

export const caAddRoles = gql`
  mutation caAddRoles($input: caAddRolesInput){
    caAddRoles(input: $input){
        status
    }
  }
`;

export const caGetDataEditRole = gql`
  query caGetDataEditRole($roleId: Int!){
    caGetDataEditRole(roleId: $roleId){
        aw_rp_base_amount_limit
        aw_stc_base_amount_limit
        company_id
        count_users
        default
        id
        is_default_disabled
        name
        order_base_amount_limit
        permissions {
            role
        }
    }
  }
`;

export const caEditRoles = gql`
  mutation caEditRoles($input: caEditRolesInput){
    caEditRoles(input: $input){
        status
    }
  }
`;

export const GET_COMPANY_ADDRESSES = gql`
    query getCompanyAddress($companyId: Int!, $pageSize: Int!, $currentPage: Int!) {
        getCompanyAddress(company_id: $companyId, pageSize: $pageSize, currentPage: $currentPage) {
            items {
                company
                entity_id
                street
                postcode
                region
                region_id
                city
                telephone
                country_id
                country_label
                longitude
                latitude
                status
            }
            totalData
        }
    }
`;

export const ADD_COMPANY_ADDRESS = gql`
    mutation addCompanyAddress(
        $companyId: Int!
        $company: String
        $city: String
        $postcode: String
        $countryId: String!
        $countryLabel: String
        $telephone: String
        $street: String
        $region: String
        $regionId: Int
        $longitude: String
        $latitude: String
    ) {
        addCompanyAddress(
            company_id: $companyId
            company: $company
            city: $city
            postcode: $postcode
            street: $street
            telephone: $telephone
            region: $region
            region_id: $regionId
            longitude: $longitude
            latitude: $latitude
            country_id: $countryId
            country_label: $countryLabel
        ) {
            entity_id
            city
        }
    }
`;

export const UPDATE_COMPANY_ADDRESS = gql`
    mutation editCompanyAddress(
        $companyId: Int!
        $entityId: Int!
        $company: String
        $city: String
        $postcode: String
        $countryId: String!
        $countryLabel: String
        $telephone: String
        $street: String
        $region: String
        $regionId: Int
        $longitude: String
        $latitude: String
    ) {
        editCompanyAddress(
            company_id: $companyId
            entity_id: $entityId
            company: $company
            city: $city
            postcode: $postcode
            street: $street
            telephone: $telephone
            region: $region
            region_id: $regionId
            longitude: $longitude
            latitude: $latitude
            country_id: $countryId
            country_label: $countryLabel
        ) {
            entity_id
            city
        }
    }
`;

export const DELETE_COMPANY_ADDRESS = gql`
    mutation deleteCompanyAddress($entityId: Int!) {
        deleteCompanyAddress(entity_id: $entityId) {
            message
            status
        }
    }
`;

export const GET_COMPANY_ADDRESSES_FOR_SELECT = gql`
    query getCompanyAddress($companyId: Int!) {
        getCompanyAddress(company_id: $companyId, pageSize: 100, currentPage: 1) {
            items {
                company
                entity_id
                region
                city
                street
                postcode
                fax
                longitude
                latitude
                status
            }
        }
    }
`;

export const GET_CUSTOMER_CODE = gql`
    query getCustomerCode {
        customer {
            cust_code
        }
    }
`;

export const ADD_COMPANY_USER = gql`
    mutation addCompanyUser($input: caAddCustomerUserInput) {
        caAddCustomerUser(input: $input) {
            status
        }
    }
`;

export const EDIT_COMPANY_USER = gql`
    mutation editCompanyUser($input: caEditCustomerUserInput) {
        caEditCustomerUser(input: $input) {
            status
        }
    }
`;

export const SEND_INVITATION_TO_REGISTER = gql`
    mutation sendInvitationToRegister($email: String!) {
        sendInvitationEmail(email: $email) {
            email
        }   
    }
`;

export const getCompanyInfoSchema = gql`
    query {
        getCompanyInformation(
            pageSize: 1
            currentPage: 20
        ) {
            id
            name
            legal_name
            email
            street
            city
            region
            country
            postcode
            telephone
            status
            tax_id
            re_seller_id
            ktp
            npwp
            npwp_file {
                file_name
                url_download
            }
            tdp
            tdp_file {
                file_name
                url_download
            }
            siup
            siup_file {
                file_name
                url_download
            }
            admin_name
            admin_email
            admin_telephone
            admin_job
            sales_name
            sales_email
        }
    }
`;

export const editCompanySchema = gql`
    mutation editCompanyAccount($company: editCompanyAccountInput) {
        editCompanyAccount(
            company: $company
    ) {
        company
    }
}
`;

export const quotationlistSchema = gql`
    query getQuotationList($pageSize: Int!, $currentPage: Int!) {
        ctqQuoteList(pageSize: $pageSize, currentPage: $currentPage)  {
            items {
                created_at
                name
                customer_name
                id
                last_updated
                quote_total
                status
            }
            totalData
        }
    }
`;

export const getQuotationProductSchema = gql`
    query getQuoteProduct($quoteId: Int!) {
        ctqViewQuoteProducts(quoteId: $quoteId) {
            grand_total {
                currency
                value
            }
            items {
                item_id
                name
                price {
                    currency
                    value
                }
                qty
                sku
                subtotal {
                    currency
                    value
                }
                proposed_price {
                    currency
                    value
                }
            }
            negotiated_discount {
                currency
                value
            }
            rewardpoints {
                currency
                value
            }
            shipping_amount {
                currency
                value
            }
            store_credit {
                currency
                value
            }
            subtotal {
                currency
                value
            }
            view_quote_header {
                created
                id
                name
                status
            }
        }
    }
`;

export const getQuotationCommentSchema = gql`
    query getQuoteComment($quoteId: Int!) {
        ctqViewQuoteComments(
            quoteId: $quoteId
        ) {
            comments {
                attachments {
                    file_name
                    url_download
                }
                comment
                created_at
                owner_name
            }
            view_quote_header {
                created
                id
                name
                status
            }
        }
    }
`;

export const getQuotationHistorySchema = gql`
    query getQuoteHistory($quoteId: Int!) {
        ctqViewQuoteHistory(
            quoteId: $quoteId
            pageSize: 20
            currentPage: 1
        ) {
            items {
                actions {
                    additional_information
                    name
                    old_value
                    status
                    type
                    value
                }
                created_at
                id
                owner_name
                status
            }
            view_quote_header {
                created
                id
                name
                status
            }
        }
    }
`;

export const addQuoteCommentSchema = gql`
    mutation addQuoteComment(
        $attachments: [InputAttachments]
        $comment: String!
        $quoteId: Int!
    ) {
        ctqAddComments(
            input: {
                attachments: $attachments
                comment: $comment,
                quote_id: $quoteId
            }
        ) {
            status
        }
    }
`;

export const ctqExportDocumentSchema = gql`
    query ctqExportDocument($quoteId: Int!){
        ctqExportDocument(quoteId: $quoteId) {
            file_name
            document
        }
    }
`;

export const ctqDeclineQuoteSchema = gql`
    query ctqDeclineQuote($quoteId: Int!){
        ctqDeclineQuote(quoteId: $quoteId) {
            status
        }
    }
`;

export const ctqSubmitForApprovalSchema = gql`
    mutation ctqSubmitForApproval($input: ctqSubmitForApprovalInput){
        ctqSubmitForApproval(input: $input){
            status
        }
    }
`;

export const ctqBuyQuoteSchema = gql`
    query ctqBuyQuote($quoteId: Int!){
        ctqBuyQuote(quoteId: $quoteId) {
            status
            cart_id
        }
    }
`;

export const ctqReQuoteSchema = gql`
    query ctqReQuote($quoteId: Int!){
        ctqReQuote(quoteId: $quoteId) {
            re_quote_id
            status
        }
    }
`;

export const creditLimitSchema = gql`
    {
        awCreditLimitDetail {
            available_credit {
                currency
                value
            }
            credit_balance {
                currency
                value
            }
            credit_limit {
                currency
                value
            }
        }
    }
`;

export const creditLimitStatusSchema = gql`
    {
        isCreditLimitDetailVisible {
            status
        }
    }
`;

export const GET_CA_CUSTOMER_USER_LIST = gql`
    query caCustomerUserList($currentPage: Int!, $pageSize: Int!) {
        caCustomerUserList(currentPage: $currentPage, pageSize: $pageSize) {
            items {
                id
                name
                email
                role
                job_position
                status
                user_role_id
                branch_code
                cust_code
            }
            totalData
        }
    }
`;

export const GET_CUSTOMER_USERS_BY_NPWP = gql`
    query getCustomerUsersByNpwp($npwp: String!) {
        getCustomerUserByNpwp(npwp: $npwp) {
            owner_name
            email
            role
            position
            status
            branch_code
        }
    }
`;

export const GET_COMPANY_NPWP = gql`
    query getCompanyNpwp {
        getCompanyInformation(
            pageSize: 10
            currentPage: 1
        ) {
            npwp
        }
    }
`;

export const getUnpaidInvoiceSchema = gql`
    query {
        getUnpaidInvoice {
            invoice_id
            invoice_status
            order_id
            grand_total {
                value
                currency
            }
            invoice_created_at
            qty
            faktur_pajak_pdf
            days_overdue
            invoice_pdf
        }
    }
`;

export const getPendingBillingSchema = gql`
    query {
        getPendingBilling {
            billing_id
            invoice_list
            grand_total {
                value
                currency
            }
        }
    }
`;

export const createBillingSchema = gql`
    mutation createBilling($invoice_ids: [String]!) {
        createBilling(
            input: {
                invoice_ids: $invoice_ids
            }
        ) {
            billing_id
        }
    }
`;

export const payBillingSchema = gql`
    mutation payBilling($billing_id: Int!, $payment_code: String!) {
        payBilling(
            input: {
                billing_id: $billing_id,
                payment_code: $payment_code
            }
        ) {
            url
        }
    }
`;

export const availablePaymentBillingSchema = gql`
    query {
        availablePaymentBilling {
            payment_title
            payment_code
    }
}
`;
