import makeStyles from '@material-ui/core/styles/makeStyles';
import { FlexRow } from '@theme_mixins';
import { GRAY_PRIMARY, PRIMARY } from '@theme_color';

export default makeStyles((theme) => ({
    container: {
        ...FlexRow,
    },
    iconBtn: {
        marginRight: 0,
        padding: 0,
        '& .MuiSvgIcon-root': {
            fontSize: '16px !important',
            [theme.breakpoints.down('sm')]: {
                fontSize: '10px !important',
            },
        },
    },
    icon: {
        color: GRAY_PRIMARY,
        '&:hover': {
            color: `${PRIMARY} !important`,
        },
    },
    iconActive: {
        color: '#F7C317',
    },
}));
