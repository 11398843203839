import { makeStyles } from '@material-ui/core/styles';
import { Centering } from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    caraousel: {
        width: '100%',
        height: '100%',
        position: 'relative',
        '& .slick-dots li button:before': {
            fontSize: '9px',
            color: '#F1F1F1',
        },
        '& .slick-dots li.slick-active button:before': {
            color: '#6BC498',
        },
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100vw',
        },
    },
    arrow: {
        fontSize: '1.5rem',
        position: 'absolute',
        ...Centering,
        padding: 10,
        borderRadius: 5,
        textAlign: 'center',
        paddingLeft: 10,
        top: 'calc(40% - 1rem)',
        width: 40,
        height: 40,
        cursor: 'pointer',
        // '&:hover': {
        //     backgroundColor: PRIMARY,
        //     color: WHITE,
        // },
        // [theme.breakpoints.down('sm')]: {
        //     display: 'none',
        // },
    },
    leftArrow: {
        left: 20,
    },

    rightArrow: {
        right: 20,
    },
}));

export default useStyles;
