/* eslint-disable no-nested-ternary */
import { getHost } from '@helper_config';
import {
    FacebookShareButton,
    LineIcon,
    LineShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    PinterestIcon,
    PinterestShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterShareButton,
    EmailIcon,
    EmailShareButton,
    WhatsappShareButton,
} from 'react-share';
import useStyles from '@core_modules/product/pages/default/components/SharePopup/style';
import { shareIconConfig } from '@services/graphql/repository/pwa_config';

const ItemShare = (props) => {
    const { link = getHost() } = props;
    const styles = useStyles();

    let shareIcon = {};

    const { data: dataConfigIcon, loading } = shareIconConfig();

    if (!loading && dataConfigIcon && dataConfigIcon.storeConfig && dataConfigIcon.storeConfig.pwa) {
        shareIcon = {
            ...dataConfigIcon.storeConfig.pwa,
        };
    }

    const data = Object.entries(shareIcon);
    return (
        <div className={styles.iconContainer}>
            {data.map((item, key) => (item[0] === 'share_icon_twitter' && item[1] === true ? (
                <TwitterShareButton url={link} key={key}>
                    <img
                        src="/assets/img/twitterIcon.svg"
                        alt="twitter"
                    />
                </TwitterShareButton>
            ) : item[0] === 'share_icon_facebook' && item[1] === true ? (
                <FacebookShareButton url={link} key={key}>
                    <img
                        src="/assets/img/facebookIcon.svg"
                        alt="facebook"
                    />
                </FacebookShareButton>
            ) : item[0] === 'share_icon_line' && item[1] === true ? (
                <LineShareButton url={link} key={key}>
                    <LineIcon size={30} />
                </LineShareButton>
            ) : item[0] === 'share_icon_pinterest' && item[1] === true ? (
                <PinterestShareButton url={link} key={key}>
                    <PinterestIcon size={30} />
                </PinterestShareButton>
            ) : item[0] === 'share_icon_telegram' && item[1] === true ? (
                <TelegramShareButton url={link} key={key}>
                    <TelegramIcon size={30} />
                </TelegramShareButton>
            ) : item[0] === 'share_icon_email' && item[1] === true ? (
                <EmailShareButton url={link} key={key}>
                    <EmailIcon size={30} />
                </EmailShareButton>
            ) : (
                item[0] === 'share_icon_linkedin'
                    && item[1] === true && (
                    <LinkedinShareButton url={link} key={key}>
                        <LinkedinIcon size={30} />
                    </LinkedinShareButton>
                )
            )))}
            <WhatsappShareButton url={link}>
                <img
                    src="/assets/img/whatsappIcon.svg"
                    alt="whislist"
                />
            </WhatsappShareButton>
        </div>
    );
};

export default ItemShare;
