import React from 'react';
import HomeCategorySkeleton from '../Categories/HomeCategorySkeleton';
import useStyles from './style';

const PromoSkeleton = () => {
    const styles = useStyles();
    return (
        <div className={styles.wrapperSkeleton}>
            <HomeCategorySkeleton />
        </div>
    );
};

export default PromoSkeleton;
