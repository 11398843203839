/* eslint-disable consistent-return */
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from 'next/link';
import Carousel from '@core_modules/commons/Slick/Caraousel/index';
import React, { useState } from 'react';
import classNames from 'classnames';
import ProductItem from '@plugin_productitem';
import { categoryPromo } from '@core_modules/home/service/graphql/index';
import { getVendor } from '@root/src/helpers/cookies';
import { getLoginInfo } from '@root/core/helpers/auth';
import useStyles from './style';
import PromoSkeleton from './PromoSkeleton';

const TopSellerProductTabPanel = (props) => {
    const { children, value, index } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const TopSellerProductTab = ({
    tabsLabel, tabsLink, tabsDataProduct, companyStatus, customerCode,
}) => {
    const [activeTabs, setActiveTabs] = useState(0);
    const handleChangeTabs = (event, value) => {
        setActiveTabs(value);
    };

    const a11yProps = (index) => ({
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    });

    const styles = useStyles();

    return (
        <div className={styles.wrapper}>
            <AppBar position="static" color="default" className={styles.tabs}>
                <div className={styles.tabsNameWrapper}>
                    <Tabs
                        value={activeTabs}
                        onChange={handleChangeTabs}
                        variant="scrollable"
                        indicatorColor="primary"
                        textColor="primary"
                        scrollButtons="off"
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: '#6BC498',
                                height: '3px',
                            },
                        }}
                    >
                        {tabsLabel.map((label, key) => <Tab className={styles.tabsHeader} label={label} key={key} {...a11yProps(key)} />)}
                    </Tabs>
                    <Link href={tabsLink[activeTabs]}>
                        <a className={classNames(styles.linkAllWrapper, 'hidden-mobile')}>
                            <div>
                                <span>Lihat semua</span>
                                <img src="/assets/img/nextall.svg" alt="see-all" />
                            </div>
                        </a>
                    </Link>

                </div>
            </AppBar>
            {tabsDataProduct.map((products, key) => (
                <TopSellerProductTabPanel value={activeTabs} index={key} key={key}>
                    <Carousel
                        data={products.map((product) => ({ ...product }))}
                        slideXs={2}
                        slideSm={2}
                        slideMd={4}
                        slideLg={5}
                        Item={ProductItem}
                        companyStatus={companyStatus}
                        customerCode={customerCode}
                        centerMode={false}
                        infinite
                        isHomepage
                        enableWishlist={false}
                    />
                </TopSellerProductTabPanel>
            ))}

            <div>
                <Link href={tabsLink[activeTabs]}>
                    <a className={classNames(styles.mobileLink, 'hidden-desktop')}>
                        <div>
                            <span>Lihat semua</span>
                            <img src="/assets/img/nextall.svg" alt="see-all" />
                        </div>
                    </a>
                </Link>
            </div>
        </div>
    );
};

const RenderContentTabs = ({ companyStatus, customerCode }) => {
    let isLogin = '';
    const vendorId = getVendor();

    if (typeof window !== 'undefined') isLogin = getLoginInfo();
    const context = isLogin && isLogin === 1 ? { request: 'internal' } : {};

    const config = {
        vendorId,
    };

    const { data, loading } = categoryPromo(config, { context });

    if (loading) {
        return <PromoSkeleton />;
    }

    if (data
        && data.categories
        && data.categories.items
        && data.categories.items.length > 0
    ) {
        const tabsLabel = data.categories.items[0].children.map((label) => label.name);
        const tabsLink = data.categories.items[0].children.map((link) => link.url_path);
        const tabsDataProduct = data.categories.items[0].children.map((child) => child.products.items);
        return (
            <TopSellerProductTab
                tabsLabel={tabsLabel}
                tabsLink={tabsLink}
                tabsDataProduct={tabsDataProduct}
                companyStatus={companyStatus}
                customerCode={customerCode}
            />
        );
    }

    return null;
};

const Promo = ({ companyStatus = '', customerCode = '' }) => (
    <>
        <RenderContentTabs companyStatus={companyStatus} customerCode={customerCode} />
    </>
);

export default Promo;
