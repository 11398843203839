import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '51px 88px 60px 88px',
        textAlign: 'center',
        '& .slick-slide > div': {
            margin: '0 7px',
            [theme.breakpoints.down('sm')]: {
                margin: '0 7px',
            },
        },
        '& .slick-slide > div > div': {
            width: '100%',
        },
        '& .slick-slide img': {
            width: '100%',
            height: '122px',
            objectFit: 'contain',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '42px 16px',
        },
    },
    titleWrapper: {
        marginBottom: '29px',
    },
    brandTitle: {
        fontWeight: 'bold',
        textTransform: 'capitalize',
        fontSize: '30px',
        marginBottom: '11px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '22px',
        },
    },
    brandLink: {
        border: '1px solid #6BC498',
        borderRadius: '15px',
        // height: '31px',
        minWidth: '144px',
        display: 'inline-block',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '6px 16px',
        textTransform: 'capitalize',
        '& span': {
            marginRight: '6px',
        },
        // padding: '8px 16px'
    },
}));

export default useStyles;
