import FormControl from '@material-ui/core/FormControl';
import React from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import classNames from 'classnames';
import Typography from '@common_typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from '@common_textfield/style';
import PhoneInput from 'react-phone-number-input';

const CustomTextField = ({
    type = 'text',
    placeholder = '',
    disabled = false,
    onChange = () => {},
    value = '',
    className = '',
    label = '',
    fullWidth = true,
    shrink = true,
    error = false,
    errorMessage = '',
    variant = 'standard',
    loading = false,
    required = false,
    styled = false,
    footer,
    ...other
}) => {
    const styles = useStyles();
    const customClass = classNames(styles.container, styled && styles.textField, className);

    let customTextFieldInput = (
        <FormControl required={required} disabled={disabled || loading} fullWidth={fullWidth} error={error} variant={variant} className={customClass}>
            <InputLabel shrink={shrink} htmlFor={label} className={styles.label} classes={{ asterisk: styled && styles.asterisk }}>
                {label}
            </InputLabel>
            <Input
                // id={label}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                endAdornment={<>{loading ? <CircularProgress color="inherit" size={20} /> : null}</>}
                type={type}
                {...other}
            />
            {React.isValidElement(footer) ? (
                footer
            ) : (
                <Typography variant="p" color={error ? 'red' : 'default'} className={styles.errorMessage}>
                    {errorMessage}
                </Typography>
            )}
        </FormControl>
    );
    if (type === 'phone') {
        let inputValue = value;
        if (value && value !== '' && value[0] === '0') {
            inputValue = `+62${inputValue.substring(1)}`;
        }
        customTextFieldInput = (
            <FormControl disabled={disabled || loading} fullWidth={fullWidth} error={error} variant={variant} className={customClass}>
                <InputLabel shrink={shrink} htmlFor={label} className={styles.label}>
                    {label}
                </InputLabel>

                <PhoneInput
                    disabled={disabled || loading}
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="ID"
                    value={inputValue}
                    onChange={onChange}
                />

                {React.isValidElement(footer) ? (
                    footer
                ) : (
                    <Typography variant="p" color={error ? 'red' : 'default'} className={styles.errorMessage}>
                        {errorMessage}
                    </Typography>
                )}
            </FormControl>
        );
    }
    return customTextFieldInput;
};

export default CustomTextField;
