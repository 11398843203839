/* eslint-disable react/no-danger */
/* eslint-disable consistent-return */
import React from 'react';
import parse from 'html-react-parser';
import useStyles from '@core_modules/cms/components/cms-renderer/magezon/style';
import Categories from '@core_modules/home/pages/default/components/Categories/index';
import Promo from '@core_modules/home/pages/default/components/Promo/index';
import LimitedOffer from '@core_modules/home/pages/default/components/LimitedOffer/index';
import HomeBrand from '@core_modules/home/pages/default/components/HomeBrand/index';

const MagezonRawHtml = (props) => {
    const {
        xs_hide, sm_hide, md_hide, lg_hide, content, storeConfig,
    } = props;
    const styles = useStyles(props);
    const { companyStatus = '', customerCode = '' } = storeConfig;
    let classes = '';
    if (xs_hide) classes += 'hidden-mobile ';
    if (sm_hide) classes += 'hidden-sm ';
    if (md_hide) classes += 'hidden-md ';
    if (lg_hide) classes += 'hidden-lg ';

    const options = {
        replace: (domNode) => {
            if (!domNode.attribs) {
                return;
            }
            if (domNode.attribs && domNode.attribs.class === 'custom-homepage-category') {
                return (
                    <Categories companyStatus={companyStatus} customerCode={customerCode} />
                );
            }
            if (domNode.attribs && domNode.attribs.class === 'custom-homepage-promo') {
                return (
                    <Promo companyStatus={companyStatus} customerCode={customerCode} />
                );
            }
            if (domNode.attribs && domNode.attribs.class === 'custom-homepage-limited-offer') {
                return (
                    <LimitedOffer companyStatus={companyStatus} customerCode={customerCode} />
                );
            }
            if (domNode.attribs && domNode.attribs.class === 'pwa-custom-brands') {
                return (
                    <HomeBrand companyStatus={companyStatus} customerCode={customerCode} />
                );
            }
        },
    };

    return (
        <div className={`${styles.container} ${classes}`}>
            {/* <div className="magezone-html" dangerouslySetInnerHTML={{ __html: content }} /> */}
            <div className="magezone-html">
                {parse(content, options)}
            </div>
        </div>
    );
};

export default MagezonRawHtml;
