import { makeStyles } from '@material-ui/core/styles';
import { CreateMargin } from '@theme_mixins';

const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
        height: '100%',
        maxHeight: 100,
        ...CreateMargin(10, 0, 20, 0),
    },
    label: {
        textTransform: 'capitalize',
        transform: 'translate(0, -4px)',
        fontSize: 14,
        fontWeight: 'bold',
        color: 'black',
    },
    asterisk: {
        position: 'absolute',
        top: -4,
        right: -8,
        color: 'red',
        display: 'inline-block',
    },
    textField: {
        marginBottom: 4,
        '& .MuiInputBase-input': {
            borderRadius: 5,
            border: '1px solid #989898',
            padding: '11px 12px',
            fontSize: 14,
            backgroundColor: 'white',
        },
        '& .MuiInput-underline:before, & .MuiInput-underline:after, .MuiInput-underline:hover:before': {
            border: 'none',
        },
    },
    errorMessage: {
        fontSize: 11,
        fontWeight: 400,
    },
}));

export default useStyles;
