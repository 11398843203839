import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@common_typography';
import classNames from 'classnames';
import useStyles from '@common_select/style';
import useTextFieldStyles from '@common_textfield/style';

const Select = ({
    label = '',
    name = '',
    value = null,
    onChange = () => {},
    options = [],
    placeholder = '',
    helperText = 'Please Select',
    className = '',
    error = false,
    errorMessage = '',
    showLabel = true,
    fullWidth = true,
    styled = false,
    ...other
}) => {
    const styles = useStyles();
    const textFieldStyles = useTextFieldStyles();
    const rootClass = classNames(styled && textFieldStyles.textField, styles.root, className);
    return (
        <TextField
            id={name}
            select
            label={showLabel && label}
            name={name}
            value={value}
            onChange={onChange}
            fullWidth={fullWidth}
            InputLabelProps={{
                shrink: true,
                classes: { root: styled && textFieldStyles.label, asterisk: styled && textFieldStyles.asterisk },
            }}
            className={rootClass}
            {...other}
            placeholder={placeholder || helperText}
            error={error}
            helperText={
                error && (
                    <Typography variant="span" color={error ? 'red' : 'default'}>
                        {errorMessage}
                    </Typography>
                )
            }
        >
            <MenuItem disabled selected value="">
                {placeholder || helperText}
            </MenuItem>
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default Select;
