import React from 'react';
import Skeleton from '@common_skeleton';
import useStyles from './style';

const HomeCategorySkeleton = () => {
    const styles = useStyles();

    return (
        <div className={styles.container}>
            <div className={styles.skeletonTitle}>
                <Skeleton
                    variant="rect"
                    width="60%"
                    height={22}
                    animation="wave"
                />
            </div>
            <div className={styles.skeletonGrid}>
                <Skeleton
                    variant="rect"
                    width="100%"
                    height={141}
                    animation="wave"
                />
                <Skeleton
                    variant="rect"
                    width="100%"
                    height={141}
                    animation="wave"
                />
                <Skeleton
                    variant="rect"
                    width="100%"
                    height={141}
                    animation="wave"
                />
                <Skeleton
                    variant="rect"
                    width="100%"
                    height={141}
                    animation="wave"
                />
                <Skeleton
                    variant="rect"
                    width="100%"
                    height={141}
                    animation="wave"
                />
            </div>
        </div>
    );
};

export default HomeCategorySkeleton;
