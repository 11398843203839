import MagezonElement from '@core_modules/cms/components/cms-renderer/magezon/index';
import classNames from 'classnames';

const MagezonSection = (props) => {
    const {
        elements, el_class, el_id, storeConfig,
    } = props;

    return (
        <>
            <div id={el_id} className={classNames('mgz-section', el_class)}>
                {elements.map((element, index) => (
                    <div className="mgz-section-item">
                        <MagezonElement key={index} {...element} storeConfig={storeConfig} />
                    </div>
                ))}
            </div>
            <style jsx>
                {`
                    .mgz-section-item {
                        margin: 20px 0;
                    }
                `}
            </style>
        </>
    );
};

export default MagezonSection;
