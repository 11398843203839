import React from 'react';
import classNames from 'classnames';
import useStyles from '@plugin_customizableitem/components/style';
import withStyles from '@material-ui/core/styles/withStyles';
import InputBase from '@material-ui/core/InputBase';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const Input = withStyles({
    root: {
        'label + &': {
            marginTop: 5,
        },
        padding: 0,
    },
    input: {
        borderRadius: 5,
        position: 'relative',
        border: '1px solid #ced4da',
        fontSize: 14,
        width: '100%',
        padding: '0 16px',
        height: 40,
    },
})(InputBase);

const Label = withStyles({
    root: {
        fontWeight: 'bold',
        fontSize: 12,
        color: 'black',
        transform: 'scale(1)',
        textTransform: 'capitalize',
    },
    asterisk: {
        color: '#E02B27',
        fontWeight: 'bold',
    },
})(InputLabel);

const ViewCustomizableFieldOption = ({
    title = 'test',
    data = {},
    value = '',
    disabled, onChange = () => {},
    error = '',
    required = false,
    customFreeGift = false,
}) => {
    const styles = useStyles();
    const customClass = classNames('column', styles.container, styles.customizableFieldOption);
    return (
        <div className={customClass}>
            {data && data.uid && (
                <Box display="flex" flexDirection="column" mb={customFreeGift ? 1 : 4}>
                    <FormControl required={required} disabled={disabled} error={error}>
                        <Label shrink htmlFor={data.uid}>
                            {data.label}
                        </Label>
                        <Input id={data.uid} name={title} value={value} onChange={onChange} aria-describedby={`${data.uid}-helper`} />
                        <FormHelperText id={`${data.uid}-helper`}>{error}</FormHelperText>
                    </FormControl>
                </Box>
            )}
        </div>
    );
};

export default ViewCustomizableFieldOption;
