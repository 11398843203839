import Layout from '@layout';
import { getCmsPage } from '@core_modules/cms/services/graphql';
import {
    getCompanyInformation,
    getCustomerCode,
} from '@core_modules/product/services/graphql';
import Content from '@core_modules/cms/pages/default/components';

const CmsSlug = (props) => {
    const {
        pageConfig, t, slug, isLogin, ...other
    } = props;
    const { data, error, loading } = getCmsPage({ identifier: slug[0] });
    const { loading: loadingCompany, data: dataCompanyInfo } = getCompanyInformation({
        skip: isLogin === 0,
    });
    const { loading: loadingCustCode, data: dataCustCode } = getCustomerCode({
        skip: isLogin === 0,
    });
    const companyStatus = dataCompanyInfo?.getCompanyInformation?.status;
    const customerCode = dataCustCode?.customer?.cust_code;

    const Config = {
        title: data && data.cmsPage ? data.cmsPage.title : '',
        headerTitle: data && data.cmsPage ? data.cmsPage.title : '',
        bottomNav: false,
        header: 'relative', // available values: "absolute", "relative", false (default)
    };

    return (
        <Layout {...props} pageConfig={pageConfig || Config}>
            <Content
                data={data}
                companyStatus={companyStatus}
                customerCode={customerCode}
                t={t}
                loading={loading || loadingCompany || loadingCustCode}
                error={error}
                {...other}
            />
        </Layout>
    );
};

export default CmsSlug;
