/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable max-len */
import Button from '@material-ui/core/IconButton';
import PriceFormat from '@common_priceformat';
import RatingStar from '@common_ratingstar';
import Typography from '@common_typography';
import { modules } from '@config';
import Link from 'next/link';
import React from 'react';
import Favorite from '@material-ui/icons/Favorite';
// import FavoriteBorderOutlined from '@material-ui/icons/FavoriteBorderOutlined';
import classNames from 'classnames';
import useStyles from '@plugin_productitem/style';

const Detail = (props) => {
    const {
        spesificProduct,
        handleClick,
        name,
        handleFeed,
        ratingValue,
        reviewCount,
        __typename,
        price_range,
        price_tiers,
        feed,
        id,
        special_from_date,
        special_to_date,
        enableWishlist,
        handleSetCompareList,
        enableRating,
        enablePrice = true,
        enableProductCompare,
        storeConfig = {},
        isLogin,
        // stock_status,
        isHomepage,
        vendor_product_data,
        t,
        companyStatus,
        customerCode,
        url_key,
    } = props;
    const vendorProductInStock = vendor_product_data?.[0]?.vendor_id
        && vendor_product_data?.[0]?.final_price;

    const styles = useStyles();
    const classFeedActive = classNames(styles.iconFeed, styles.iconActive);
    const FeedIcon = feed ? <Favorite className={classFeedActive} /> : <img className={styles.iconFeed} src="/assets/img/whislistIcon.svg" alt="whislist" />;
    const showWishlist = typeof enableWishlist !== 'undefined' ? enableWishlist : modules.wishlist.enabled;
    const showRating = typeof enableRating !== 'undefined' ? enableRating : storeConfig?.pwa?.rating_enable;
    return (
        <div className={styles.descItem}>
            <Link href="/[...slug]" as={`/${url_key}`}>
                <a onClick={handleClick} className={styles.productLinkButton}>
                    {
                        isHomepage ? (
                            <Typography variant="p" className={styles.productTitle} letter="capitalize">
                                {name.length > 10 ? `${name.substring(0, 30)} ...` : name}
                            </Typography>
                        ) : (
                            <Typography variant="p" className={styles.productTitle} letter="capitalize">
                                {name.length > 40 ? `${name.substring(0, 40)} ...` : name}
                            </Typography>
                        )
                    }
                </a>
            </Link>
            {(enablePrice && isLogin === 1 && companyStatus === 'approved' && customerCode) && (
                <div className={styles.priceWrapper}>
                    {
                        !vendorProductInStock ? (
                            <Typography variant="p" className={styles.outOfStock} letter="capitalize">
                                {t('catalog:outOfStock')}
                            </Typography>
                        ) : (
                            <PriceFormat
                                // eslint-disable-next-line camelcase
                                priceRange={spesificProduct.price_range ? spesificProduct.price_range : price_range}
                                // eslint-disable-next-line camelcase
                                priceTiers={spesificProduct.price_tiers ? spesificProduct.price_tiers : price_tiers}
                                productType={__typename}
                                specialFromDate={special_from_date}
                                specialToDate={special_to_date}
                                vendorProductData={
                                    vendor_product_data && vendor_product_data.length > 0
                                        ? vendor_product_data[0]
                                        : null
                                }
                            />
                        )
                    }
                </div>
            )}
            <div className={styles.additionalWrapper}>
                {
                    (isLogin === 1 && companyStatus === 'approved' && customerCode) && (
                        <div>
                            {showWishlist && (
                                <Button className={styles.btnFeed} onClick={handleFeed}>
                                    {FeedIcon}
                                </Button>
                            )}
                            {enableProductCompare && (
                                <Button className={styles.btnCompare} onClick={() => handleSetCompareList(id)}>
                                    {/* <CompareArrowsIcon className={styles.iconCompare} /> */}
                                    <img className={styles.iconFeed} src="/assets/img/plpcompareIcon.svg" alt="compare" />
                                </Button>
                            )}
                        </div>
                    )
                }
                <div className={styles.ratingWrapper}>
                    { showRating && (
                        <>
                            <p className={styles.ratingText}>
                                (
                                {reviewCount}
                                )
                            </p>
                            <RatingStar value={ratingValue} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Detail;
