import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    noMargin: {
        margin: 0,
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
        '& strike': {
            fontSize: '12px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '10px',
            },
        },
    },
    asLowAs: {
        fontSize: '8px',
        '& + span': {
            fontSize: '12px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '10px',
            },
        },
    },
    discountColour: {
        color: '#989898',
    },
}));

export default useStyles;
