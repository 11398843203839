import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0,
    },
    flashSaleView: {
        marginTop: 0,
        width: '100%',
    },
    features: {
        padding: 0,
    },
    featuresBox: {
        padding: 0,
        margin: 0,
    },
    wrapper: {
        padding: '50px 73px',
        backgroundColor: '#F1F1F1',
        marginBottom: '40px',
        '& .slick-slide > div': {
            margin: '0 12px',
            [theme.breakpoints.down('xs')]: {
                margin: '0 6px',
            },
        },
        '& .slick-slide img': {
            height: '184px',
            objectFit: 'cover',
        },
        '& .slick-slide': {
            [theme.breakpoints.down('sm')]: {
                width: '157px',
            },
        },
        [theme.breakpoints.down('sm')]: {
            padding: '36px 16px',
        },
    },
    tabs: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
        marginBottom: '23px',
        '& .MuiTab-wrapper': {
            color: '#000',
            fontSize: '22px',
            fontWeight: 'bold',
            textTransform: 'capitalize',
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
            },
        },
        '& .MuiTab-root': {
            padding: '6px 14px',
        },
    },
    tabsNameWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    linkAllWrapper: {
        border: '1px solid #6BC498',
        borderRadius: '15px',
        height: '31px',
        minWidth: '135px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 16px',
        textTransform: 'capitalize',
        '& span': {
            marginRight: '6px',
        },
        // padding: '8px 16px'
    },
    mobileLink: {
        margin: '0 auto',
        border: '1px solid #6BC498',
        borderRadius: '15px',
        // height: '31px',
        minWidth: '144px',
        display: 'inline-block',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '6px 16px',
        textTransform: 'capitalize',
        '& span': {
            marginRight: '6px',
        },
    },
    wrapperSkeleton: {
        padding: '50px 30px',
        backgroundColor: '#F1F1F1',
        marginBottom: '40px',
        [theme.breakpoints.down('sm')]: {
            padding: '30x 16px',
        },
    },
}));

export default useStyles;
