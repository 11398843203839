/* eslint-disable consistent-return */
import Link from 'next/link';
import { homeBrandList } from '@core_modules/home/service/graphql/index';
import FeaturedBrands from '@core_modules/brands/pages/default/components/featured/index';
import useStyles from './style';
import HomeCategorySkeleton from '../Categories/HomeCategorySkeleton';

const HomeBrand = () => {
    const styles = useStyles();
    const { data, loading } = homeBrandList();

    if (loading) {
        return <HomeCategorySkeleton />;
    }

    const featuredData = data
        && data.getBrandList
        && data.getBrandList.featured.length > 0
        && data.getBrandList.featured.filter((item) => item.is_active === 1);
    return (
        <div className={styles.container}>
            <div className={styles.titleWrapper}>
                <h3 className={styles.brandTitle}>belanja berdasarkan brand</h3>
                <Link href="/brands">
                    <a className={styles.brandLink}>
                        lihat semua brand
                    </a>
                </Link>
            </div>
            <FeaturedBrands
                dots
                autoplay
                useTitle={false}
                desktop={false}
                featured={featuredData}
            />
        </div>
    );
};

export default HomeBrand;
