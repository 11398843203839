/* eslint-disable react/forbid-prop-types */
import Typography from '@common_typography';
import { useDropzone } from 'react-dropzone';
import React, { useMemo } from 'react';
import { useTranslation } from '@i18n';
import propTypes from 'prop-types';

const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});

const DropFile = ({
    label, title, acceptedFile, maxSize, getBase64, error, noStyle = false,
}) => {
    const { t } = useTranslation(['common']);
    const onDropAccepted = async (files) => {
        const filebase64 = await Promise.all(
            files.map(async (file) => ({
                baseCode: await toBase64(file),
                file,
            })),
        );
        getBase64(filebase64);
    };

    const messageError = `${t('common:fileUpload:reject') + acceptedFile}& max file ${maxSize / 1000000}Mb`;

    const {
        getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open,
    } = useDropzone({
        // onDrop,
        accept: acceptedFile,
        onDropAccepted,
        onDropRejected: () => window.toastMessage({
            open: true,
            text: messageError,
            variant: 'error',
        }),
        maxSize,
    });

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        breturnWidth: 2,
        breturnRadius: 2,
        breturnColor: '#eeeeee',
        breturnStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'breturn .24s ease-in-out',
        cursor: 'pointer',
    };

    const activeStyle = {
        breturnColor: '#2196f3',
    };

    const acceptStyle = {
        breturnColor: '#00e676',
    };

    const rejectStyle = {
        breturnColor: '#ff1744',
    };

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept],
    );

    return (
        <div className="column">
            {title && title !== '' ? (
                <Typography variant="label" type="semiBold" color={error ? 'red' : 'default'}>
                    {title}
                </Typography>
            ) : null}
            <div {...getRootProps({ style: noStyle ? {} : style })}>
                <input {...getInputProps()} />
                {noStyle && (
                    <button type="button" onClick={open}>
                        Choose file
                    </button>
                )}
                {!noStyle && isDragActive ? <p>{t('common:fileUpload:dragActive')}</p> : !noStyle && <p>{t('common:fileUpload:dragNonActive')}</p>}
            </div>
            <Typography color={error ? 'red' : 'default'}>{label}</Typography>
        </div>
    );
};

DropFile.propTypes = {
    label: propTypes.string,
    title: propTypes.string,
    showListFile: propTypes.bool,
    acceptedFile: propTypes.string,
    maxSize: propTypes.number,
    multiple: propTypes.bool,
    handleDrop: propTypes.func,
    getBase64: propTypes.func,
    error: propTypes.any,
    dropValue: propTypes.array,
    value: propTypes.array,
    setValue: propTypes.func,
};

DropFile.defaultProps = {
    label: '',
    title: '',
    showListFile: true,
    acceptedFile: 'image/*,.pdf,.doc,.docx,xls,xlsx,.zip,.rar',
    maxSize: 2000000,
    multiple: true,
    handleDrop: () => {},
    getBase64: () => {},
    error: false,
    dropValue: [],
    value: [],
    setValue: () => {},
};

export default DropFile;
