import { useMutation } from '@apollo/client';
import { useTranslation } from '@i18n';
import { useState } from 'react';
import { SUBSCRIBE_PRODUCT, UNSUBSCRIBE_PRODUCT } from '../services/graphql/schema';

export function useSubscribeProduct(productId, initialState) {
    const [fetchSubscribeProduct] = useMutation(SUBSCRIBE_PRODUCT, {
        context: { request: 'internal' },
    });

    const [fetchUnsubscribeProduct] = useMutation(UNSUBSCRIBE_PRODUCT, {
        context: { request: 'internal' },
    });

    const [subscribed, setSubscribed] = useState(initialState);

    async function subscribeProduct() {
        await fetchSubscribeProduct({ variables: { productId } });
        setSubscribed(true);
    }

    async function unsubscribeProduct() {
        await fetchUnsubscribeProduct({ variables: { productId } });
        setSubscribed(false);
    }

    return [subscribed, subscribeProduct, unsubscribeProduct];
}

export function useSubscribeButton(productId, initialState) {
    const { t } = useTranslation(['common', 'catalog']);

    const [loading, setLoading] = useState(false);

    const [subscribed, subscribeProduct, unsubscribeProduct] = useSubscribeProduct(productId, initialState);

    const subscribeBtnText = subscribed ? 'Notifikasi Aktif' : t('catalog:notify');

    const handleSubscribeClick = async () => {
        setLoading(true);
        try {
            if (subscribed) {
                await unsubscribeProduct();
                window.toastMessage({ open: true, variant: 'success', text: 'Kami tidak akan notifikasikan produk ini.' });
            } else {
                await subscribeProduct();
                window.toastMessage({ open: true, variant: 'success', text: 'Kami akan notifikasikan anda jika stok produk ini siap.' });
            }
        } catch (e) {
            window.toastMessage({ open: true, variant: 'error', text: t('common:error:fetchError') });
        }
        setLoading(false);
    };

    return [handleSubscribeClick, subscribeBtnText, loading];
}

export default { useSubscribeProduct };
