import Banner from '@common_slick/Banner';
// import classNames from 'classnames';
import { breakPointsUp } from '@helper_theme';
import useStyles from '@core_modules/home/pages/default/components/style';

const BannerView = (props) => {
    const { images, storeConfig } = props;
    const styles = useStyles();
    const desktop = breakPointsUp('sm');
    return (
        <div id="home-banner" className={styles.homeBannerContainer}>
            {images && images.length && <Banner height={400} data={images} showArrow={desktop} storeConfig={storeConfig} />}
        </div>
    );
};

export default BannerView;
