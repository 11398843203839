import makeStyles from '@material-ui/core/styles/makeStyles';
import { WHITE, RED } from '@theme_color';
import {
    CenterAbsolute,
    FlexColumn,
    CreateMargin,
    FlexRow,
} from '@theme_mixins';

export default makeStyles((theme) => ({
    container: {
        ...FlexRow,
        alignItems: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            ...FlexColumn,
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    freeGiftContainer: {
        ...FlexColumn,
        alignItems: 'center',
        justifyContent: 'center',
    },
    btnAddToCardContainer: {
        marginLeft: '17px',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            width: '100%',
        },
    },
    freeGiftBtnContainer: {
        margin: 0,
        width: '100%',
    },
    btnAddToCard: {
        [theme.breakpoints.down('sm')]: {
            ...CenterAbsolute,
        },
        [theme.breakpoints.up('sm')]: {
            // minWidth: 316,
            float: 'left',
            maxWidth: '100%',
        },
        // ...CreateMargin(0, 8, 0, 0),
        width: '100%',
        height: 44,
        bottom: 0,
        left: 0,
        opacity: 'none',
        color: WHITE,
        borderRadius: '5px',
        backgroundColor: '#185A58',
        fontSize: '16px',
        '& img': {
            marginRight: '11px',
        },
    },
    textBtnAddToCard: {
        fontSize: 16,
        color: `${WHITE} !important`,
    },
    error: {
        color: RED,
    },
    qty: {
        [theme.breakpoints.down('sm')]: {
            ...CreateMargin(0, 15, 30, 15),
            alignItems: 'center',
        },
        ...CreateMargin(0, 0, 0, 0),
        ...FlexColumn,
        '& > div': {
            border: '1px solid #707070',
            borderRadius: '5px',
            height: '44px',
            width: '83px',
            '& input': {
                [theme.breakpoints.down('sm')]: {
                    border: '1px solid #707070',
                    borderRadius: '5px',
                },
            },
            '& div': {
                display: 'none',
                [theme.breakpoints.down('sm')]: {
                    display: 'flex',
                    border: 'none',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    backgroundColor: '#FFF',
                    '&:last-child': {
                        color: '#6BC498',
                    },
                    '&:first-child': {
                        color: '#DFDFDF',
                    },
                },
            },
            [theme.breakpoints.down('sm')]: {
                width: 'auto',
                border: 'none',
            },
        },
    },
    freeGiftQty: {
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
        },
        ...CreateMargin(0, 0, 12, 0),
        ...FlexColumn,
        '& > div': {
            border: '1px solid #707070',
            borderRadius: '5px',
            height: '44px',
            width: '83px',
            '& input': {
                [theme.breakpoints.down('sm')]: {
                    border: '1px solid #707070',
                    borderRadius: '5px',
                },
            },
            '& div': {
                display: 'none',
                [theme.breakpoints.down('sm')]: {
                    display: 'flex',
                    border: 'none',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    backgroundColor: '#FFF',
                    '&:last-child': {
                        color: '#6BC498',
                    },
                    '&:first-child': {
                        color: '#DFDFDF',
                    },
                },
            },
            [theme.breakpoints.down('sm')]: {
                width: 'auto',
                border: 'none',
            },
        },
    },
    qtyTitle: {
        fontSize: '12px',
    },
}));
