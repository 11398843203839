/* eslint-disable react/no-danger */
import Typography from '@common_typography';
import Button from '@common_button';
import TextField from '@common_textfield';
import ReCAPTCHA from 'react-google-recaptcha';
import dynamic from 'next/dynamic';
import useStyles from '@core_modules/contact/pages/default/components/style';
import classNames from 'classnames';
import { Box } from '@root/node_modules/@material-ui/core/index';

const Message = dynamic(() => import('@common_toast'), { ssr: false });

const ContactForm = (props) => {
    const styles = useStyles();
    const {
        t, formik, sitekey, handleChangeCaptcha, recaptchaRef, message, setMessage, load, enableRecaptcha,
    } = props;
    return (
        <form className={styles.container} onSubmit={formik.handleSubmit}>
            <Message open={message.open} variant={message.variant} setOpen={() => setMessage({ ...message, open: false })} message={message.text} />
            <Typography variant="h3" type="bold" align="left" className={styles.h3} style={{ margin: 0 }}>
                {t('contact:formTitle')}
            </Typography>
            <Typography variant="p" align="left" className={styles.p} style={{ margin: 0 }}>
                {t('contact:formDetail')}
            </Typography>
            <TextField
                label={t('contact:fullName')}
                variant="outlined"
                className={styles.textField}
                name="fullName"
                value={formik.values.fullName}
                onChange={formik.handleChange}
                error={!!(formik.touched.fullName && formik.errors.fullName)}
                errorMessage={(formik.touched.fullName && formik.errors.fullName) || null}
            />
            <TextField
                label={t('contact:email')}
                className={styles.textField}
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={!!(formik.touched.email && formik.errors.email)}
                errorMessage={(formik.touched.email && formik.errors.email) || null}
            />
            <TextField
                label={t('contact:telephone')}
                className={styles.textField}
                name="telephone"
                value={formik.values.telephone}
                onChange={formik.handleChange}
                error={!!(formik.touched.telephone && formik.errors.telephone)}
                errorMessage={(formik.touched.telephone && formik.errors.telephone) || null}
            />
            <TextField
                label={t('contact:message')}
                className={styles.textField}
                name="message"
                multiline
                rows="4"
                value={formik.values.message}
                onChange={formik.handleChange}
                error={!!(formik.touched.message && formik.errors.message)}
                errorMessage={(formik.touched.message && formik.errors.message) || null}
                style={{ paddingBottom: 0 }}
            />
            <Box pb="20px" />
            {enableRecaptcha ? (
                <>
                    <ReCAPTCHA sitekey={sitekey} onChange={handleChangeCaptcha} ref={recaptchaRef} />
                    {formik.touched.captcha && formik.errors.captcha && <Typography color="red">{formik.errors.captcha}</Typography>}
                </>
            ) : null}
            <Button
                disabled={load}
                loading={load}
                classes={{ root: styles.button }}
                rootClassName="contact-btn-container"
                align="left"
                type="submit"
                fullWidth
            >
                {t('common:button:send')}
            </Button>
            <style jsx global>
                {`
                    .contact-btn-container {
                        margin-top: 50px;
                    }
                `}
            </style>
        </form>
    );
};

const ContactPage = (props) => {
    const styles = useStyles();
    const {
        data, t, loading, Skeleton,
    } = props;
    return (
        <>
            <Box w="100%" pb="42px">
                <img src="/assets/img/Banner-cms.png" width="100%" alt="Sgala Banner" />
            </Box>
            <Box pb="40px" mx="16px">
                <Typography variant="h2" type="bold" align="center" className={styles.mainHeading} style={{ margin: 0 }}>
                    {t('contact:pageTitle')}
                </Typography>
                <Typography variant="p" align="center" className={classNames(styles.p, styles.weAreHere)} style={{ margin: '0 auto' }}>
                    {t('contact:weAreHere')}
                </Typography>
            </Box>
            <Box pt="42px" pb="40px" mx="auto" className={classNames('row', styles.mainBox)}>
                <div className="col-md-6 col-xs-12">
                    {!loading && (
                        <div
                            className={classNames(styles.container, styles.blockContainer)}
                            dangerouslySetInnerHTML={{ __html: data.cmsBlocks.items[0].content }}
                        />
                    )}
                    {loading && <Skeleton />}
                </div>
                <div className="col-md-6 col-xs-12">
                    <ContactForm {...props} />
                </div>
            </Box>
            <Box pb="60px" />
        </>
    );
};

export default ContactPage;
