import makeStyles from '@material-ui/core/styles/makeStyles';
import { DARK_GREEN } from '@root/src/theme/colors';
import { FlexColumn } from '@theme_mixins';

export default makeStyles((theme) => ({
    pageTitles: {
        marginBottom: '20px',
        textTransform: 'uppercase',
    },
    mainBox: {
        maxWidth: 1006,
        backgroundColor: '#F1F1F1',
        letterSpacing: 'normal',
        paddingLeft: 60,
        paddingRight: 60,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 24,
            paddingRight: 24,
        },
    },
    container: {
        ...FlexColumn,
    },
    blockContainer: {
        '& h3': {
            fontSize: 16,
            paddingBottom: 7,
            margin: 0,
        },
        '& p': {
            fontSize: 14,
            lineHeight: '20px',
            margin: 0,
            marginBottom: 4,
        },
        '& p:last-child': {
            paddingBottom: 22,
        },
    },
    h3: {
        'h3&': {
            fontSize: 16,
            paddingBottom: 7,
        },
    },
    p: {
        'p&': {
            fontSize: 14,
            paddingBottom: 22,
            lineHeight: '20px',
        },
    },
    mainHeading: {
        'div &': {
            paddingBottom: 12,
            fontSize: 30,
        },
    },
    weAreHere: {
        maxWidth: 486,
        paddingBottom: 20,
        position: 'relative',
        '&:after': {
            content: '""',
            position: 'absolute',
            borderBottom: '1px solid #6BC498',
            maxWidth: 300,
            width: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            bottom: 0,
        },
    },
    skeletonForm: {
        marginBottom: 20,
    },
    button: {
        'button&': {
            maxWidth: '100%',
            backgroundColor: DARK_GREEN,
            padding: '10px 0',
            borderRadius: 5,
            fontSize: 16,
            fontWeight: 'bold',
            letterSpacing: 'normal',
            textTransform: 'capitalize',
        },
    },
    textField: {
        marginBottom: 4,
        '& input, & textarea': {
            borderRadius: 5,
            border: '1px solid #989898',
            padding: '11px 12px',
            fontSize: 14,
            backgroundColor: 'white',
        },
        '& .MuiInput-underline:before, & .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: 'none',
        },
        '& label.MuiInputLabel-shrink': {
            transform: 'translate(0, -4px)',
            fontSize: 12,
            fontWeight: 'bold',
            color: 'black',
        },
        '& label:before': {
            content: '"*"',
            position: 'absolute',
            top: -2,
            right: -8,
            color: '#E02B27',
        },
        '& > p': {
            fontSize: 12,
        },
    },
}));
