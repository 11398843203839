import React from 'react';
import dynamic from 'next/dynamic';
import classNames from 'classnames';
import Typography from '@common_typography';
import ButtonQty from '@common_buttonqty';

import useStyles from '@plugin_optionitem/components/Footer/style';

const Button = dynamic(() => import('@common_button'), { ssr: true });

const ConfigurableView = (props) => {
    const {
        loading,
        disabled,
        showQty = true,
        handleAddToCart,
        qty,
        setQty,
        t,
        showAddToCart = true,
        customStyleBtnAddToCard = '',
        labelAddToCart = '',
        maxQty = 10000,
        customButton,
        customQty = false,
        freeItemsData,
        customFreeGift = false,
    } = props;
    const styles = useStyles();

    if (customButton) {
        return customButton;
    }

    return (
        <div className={customFreeGift ? styles.freeGiftContainer : styles.container}>
            {showQty && (
                <div className={customFreeGift ? styles.freeGiftQty : styles.qty}>
                    <Typography align="center" className={styles.qtyTitle} variant="span">
                        {t('product:qty')}
                    </Typography>
                    <ButtonQty
                        value={qty}
                        onChange={setQty}
                        max={customQty ? freeItemsData.quantity : maxQty}
                        disabled={disabled}
                    />
                </div>
            )}
            {showAddToCart && (
                <div className={customFreeGift ? styles.freeGiftBtnContainer : styles.btnAddToCardContainer}>
                    <Button
                        className={
                            customFreeGift
                                ? styles.btnAddToCard
                                : classNames(styles.btnAddToCard, customStyleBtnAddToCard)
                        }
                        color="primary"
                        onClick={handleAddToCart}
                        loading={loading}
                        disabled={disabled}
                    >
                        <img src="/assets/img/mobilecart.svg" alt="cart" />
                        <Typography align="center" type="bold" letter="capitalize" color="white" variant="inherit">
                            {labelAddToCart || t('product:addToCart')}
                        </Typography>
                    </Button>
                </div>
            )}
        </div>
    );
};

export default ConfigurableView;
