import ProductItem from '@plugin_productitem';
import React from 'react';
import Link from 'next/link';
import useStyles from '@core_modules/home/pages/default/components/LimitedOffer/style';
import { categoryLimitedOffer } from '@core_modules/home/service/graphql/index';
import { getVendor } from '@root/src/helpers/cookies';
import { getLoginInfo } from '@root/core/helpers/auth';
import classNames from 'classnames';
import HomeCategorySkeleton from '../Categories/HomeCategorySkeleton';

const LimitedOffer = ({ companyStatus = '', customerCode = '' }) => {
    const styles = useStyles();
    let isLogin = '';
    const vendorId = getVendor();

    if (typeof window !== 'undefined') isLogin = getLoginInfo();
    const context = isLogin && isLogin === 1 ? { request: 'internal' } : {};

    const config = {
        vendorId,
    };
    const { data: dataCategory, loading } = categoryLimitedOffer(config, context);

    if (loading) {
        return <HomeCategorySkeleton />;
    }

    if (dataCategory && dataCategory.categories) {
        const dataProducts = dataCategory.categories.items[0].products.items;
        const urlCategory = dataCategory.categories.items[0].url_key;
        return (
            <div className={styles.container}>
                <h3 className={styles.textTitle}>Produk Penawaran Terbatas</h3>
                <div className={classNames(styles.productContainer, 'hidden-mobile')}>
                    {
                        dataProducts && dataProducts.length > 0
                            && dataProducts.map((item, i) => (
                                <ProductItem
                                    key={i}
                                    {...item}
                                    companyStatus={companyStatus}
                                    customerCode={customerCode}
                                    isHomepage
                                    enableWishlist={false}
                                />
                            ))
                    }
                </div>
                <div className={classNames(styles.productContainer, 'hidden-desktop')}>
                    {
                        dataProducts && dataProducts.length > 0
                            && dataProducts.slice(0, 6).map((item, i) => (
                                <ProductItem
                                    key={i}
                                    {...item}
                                    companyStatus={companyStatus}
                                    customerCode={customerCode}
                                    isHomepage
                                    enableWishlist={false}
                                />
                            ))
                    }
                </div>
                <div className={styles.linkWrapper}>
                    <Link href={urlCategory}>
                        <a className={styles.categoryLink}>
                            lihat semua produk
                        </a>
                    </Link>
                </div>
            </div>
        );
    }

    return null;
};

export default LimitedOffer;
