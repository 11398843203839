import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '43px 142px',
        [theme.breakpoints.down('sm')]: {
            padding: '28px',
        },
    },
    productContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        // [theme.breakpoints.down('md')]: {
        //     gridTemplateColumns: 'repeat(4, 1fr)',
        // },
    },
    textTitle: {
        textAlign: 'center',
        fontWight: 'bold',
        fontSize: '30px',
        marginBottom: '35px',
        textTransform: 'capitalize',
        [theme.breakpoints.down('sm')]: {
            fontSize: '22px',
        },
    },
    linkWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
    categoryLink: {
        margin: '0 auto',
        border: '1px solid #6BC498',
        borderRadius: '15px',
        // height: '31px',
        minWidth: '144px',
        display: 'inline-block',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '6px 16px',
        textTransform: 'capitalize',
        '& span': {
            marginRight: '6px',
        },
    },
}));

export default useStyles;
